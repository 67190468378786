.client_logo {
  background-color: #f5f8ff;
  padding: 100px 0;
}

.logo img {
  height: 110px;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
  margin: auto;
  background-color: #008cff6d;
  padding: 10px;
  border: 1px solid #ddd;
  padding: 15px;
}
