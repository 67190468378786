.frame_work {
  padding: 50px 0;
  /* background-color: #070707; */
  position: relative;
  z-index: 1;
}

/* .frame_work::after {
  content: "";
  height: 100%;
  width: 100%;
  background-image: url("../../Assets/Images/bgSafe.webp");
  position: absolute;
  top: -240px;
  left: 0;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  z-index: -1;
} */

.frame_work ul {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.frame_work ul li {
  padding: 30px;
  border: 2px solid #ffffff0a;
  height: 150px;
  background-color: #1a1a1ad5;
  /* width: 100px; */
}

.frame_work ul li img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin: auto;
}

.frame_work ul li h2 {
  font-weight: 600;
  margin-top: 10px;
}

.slider_logo {
  transition: 0.3s;
  cursor: pointer;
}

.slider_logo:hover {
  transform: scale(1.1);
}

.slider_logo img {
  width: 70px !important;
  height: 70px !important;
}

@media only screen and (max-width: 1279px) {
  .slider_logo img {
    width: 50px !important;
    height: 50px !important;
  }
  .slider_logo h2 {
    margin-top: 0px !important;
    font-size: 15px;
  }
  .frame_work ul li {
    height: 150px;
  }
}

@media only screen and (max-width: 1023px) {
  .slider_logo img {
    width: 50px !important;
    height: 50px !important;
  }
  .slider_logo h2 {
    font-size: 13px;
  }
  .frame_work ul li {
    height: 100px;
    padding: 0;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .slider_logo img {
    width: 40px !important;
    height: 40px !important;
  }
  .slider_logo h2 {
    font-size: 10px;
  }
  .frame_work ul li {
    height: 80px;
    padding: 0;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 470px) {
  .slider_logo img {
    width: 30px !important;
    height: 30px !important;
  }
  .slider_logo h2 {
    font-size: 8px;
  }
  .frame_work ul li {
    height: 70px;
  }
}
