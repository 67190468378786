.offcanvas_body {
  position: fixed;
  top: 0;
  right: -1000px;
  background-color: #fff;
  height: 100vh;
  padding: 10px;
  width: 30%;
  transition: 0.5s;
  z-index: 9999999999;
  border-left: 1px solid #00000028;
}

.custom_offcanvas input[type="checkbox"]:checked ~ .offcanvas_body {
  right: 0;
}

/* Main CSS */

.header {
  border-color: #008cff54;
}

.header h1 {
  font-size: 25px;
  font-weight: 500;
  color: #008eff;
}

.header label {
  border-color: #008cff54;
}

.form_body {
  overflow-y: auto;
  padding: 0px 10px;
}

@media only screen and (max-width: 1350px) {
  .offcanvas_body {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .offcanvas_body {
    width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .offcanvas_body {
    width: 60%;
  }
}

@media only screen and (max-width: 650px) {
  .offcanvas_body {
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .offcanvas_body {
    width: 100%;
  }
}
