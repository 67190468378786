.banner {
  /* padding: 180px 0; */
  /* background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  z-index: 1;
  height: 700px;
  position: relative;
  display: flex;
  align-items: center;
}

/* .banner::after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #1e1f277a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
} */

.banner_content {
  width: 50%;
}

.banner_content p {
  font-size: 18px;
  margin-bottom: 25px;
  color: rgba(19, 19, 19, 0.74);
}

.banner_content a.button {
  background-color: #008eff;
  color: white;
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 3px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1279px) {
  .banner {
    padding: 80px 0;
  }
  .banner_content h1 {
    font-size: 55px;
    line-height: 4rem;
  }
  .banner_content p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .banner_content button {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1023px) {
  .banner_content h1 {
    font-size: 40px;
    line-height: 3rem;
  }
  .banner_content p {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .banner_content button {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .banner {
    padding: 50px 0;
  }
  .banner_content h1 {
    font-size: 35px;
    line-height: 2.5rem;
    margin-bottom: 5px;
  }
  .banner_content p {
    font-size: 14px;
    margin-bottom: 7px;
  }
  .banner_content button {
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .banner {
    background-size: cover;
    position: relative;
    z-index: 1;
  }

  .banner::after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000000c0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .banner_content {
    width: 90%;
    margin: auto;
    text-align: center;
  }

  .banner_content ul {
    justify-content: center;
  }

  .banner_content h1 {
    color: #008eff;
    font-size: 30px;
  }

  .banner_content p {
    color: #f0f0f0;
    padding: 10px 0;
  }

  .banner_content label {
    font-size: 12px !important;
    padding: 10px 15px !important;
  }
}
