.label {
  position: relative;
  display: block;
}

input {
  background-color: #fff !important;
}

.label span {
  position: absolute;
  top: 45%;
  left: 15px;
  pointer-events: none;
  transition: 0.2s;
  background-color: #fff;
  padding: 0 5px;
}

input:focus ~ span {
  top: 8%;
  left: 15px;
  color: #008cffa9;
  font-size: 14px;
  border-right: 1px solid #008cffa9;
  border-left: 1px solid #008cffa9;
}

textarea {
  background-color: #fff !important;
}

.label_textarea {
  position: relative;
  display: block;
}

.label_textarea span {
  position: absolute;
  top: 22%;
  left: 15px;
  pointer-events: none;
  transition: 0.2s;
  padding: 0 3px;
  background-color: #fff;
}

textarea:focus ~ span {
  top: 4%;
  left: 15px;
  color: #008cffa9;
  font-size: 14px;
  border-right: 1px solid #008cffa9;
  border-left: 1px solid #008cffa9;
}
