.menu {
  position: fixed;
  top: 0;
  left: 0px;
  width: 50%;
  height: 100vh;
  z-index: 9999;
  background-color: #000;
  border-right: 1px solid #008cff85;
  padding: 15px;
  transition: 0.5s;
}

@media only screen and (max-width: 700px) {
  .menu {
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .menu {
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #008cff85;
  padding-bottom: 15px;
}

.header label {
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: flex-end;
}

.logo h1 {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.5rem;
}

.checkbox {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.checkbox:checked ~ .menu {
  left: 0;
}

.up_icon1,
.up_icon {
  display: none;
}

details[open] .up_icon {
  display: block;
}

.sub_menu[open] .up_icon1 {
  display: block;
}
details[open] .down_icon {
  display: none;
}

.sub_menu[open] .down_icon1 {
  display: none;
}

.menu_item li button,
.menu_item li details summary,
.menu_item li details summary ul li a,
.menu_item li a {
  font-size: 20px;
  color: white;
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ffffff1f;
  align-items: center;
  justify-content: space-between;
}

.menu_item li details ul li {
  margin-left: 30px;
}
