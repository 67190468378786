@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

* {
  font-family: "Ubuntu", sans-serif;
}

*::after,
*::before {
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

.swiper-pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  text-align: left;
  padding-left: 212px;
}

body {
  position: relative !important;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

p {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.cursur_pointer {
  cursor: pointer;
}

input,
textarea,
select {
  /* border: none; */
  /* border-bottom: 1px solid; */
  border-radius: 5px !important;
  outline: none;
}

input[type="checkbox"] {
  border-bottom: none !important;
}

input:focus,
textarea:focus,
select:focus {
  /* border: none; */
  outline: none !important;
  box-shadow: inherit;
  /* border-bottom: 1px solid #008eff; */
  border-color: #008eff;
}

.footer_hover:hover {
  color: #008eff;
}

.btn {
  /* background-color: #008eff !important; */
  border: none;
  padding: 13px 20px !important;
  height: auto !important;
  min-height: auto;
  color: #fff !important;
  background-color: #003e70;
}

.btn:hover {
  background-color: #001f38dc;
}

.logoBoxBg {
  background-image: linear-gradient(221deg, #7c7c7ca6, #008efa73);
}

.line_shorter {
  width: 90%;
  margin: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  /* margin-bottom: 30px; */
}
.blog_title {
  width: 100%;
  margin: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.service_single ul {
  list-style: disc;
}

.bannerVideo {
  position: relative;
}

.bannerVideo::before {
  contain: "";
  content: "";
  height: 100%;
  width: 100%;
  background-color: red;
  position: absolute;
  top: 0;
  left: 0;
}

/* Service Image */
.bgImage {
  /* background-image: url("./Assets/Images/service-bg.svg"); */
  background-image: url("https://codedna.co.nz/wp-content/uploads/2023/03/patrn-1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.serviceHover {
  transition: 0.2s;
}

.customBg {
  background-image: linear-gradient(
    120deg,
    #000 0%,
    #000000ce 30%,
    #003e70d2 100%
  );
}

@media (max-width: 1535px) {
  .service_left {
    margin-left: -20px !important;
  }
  .service_last_left {
    margin-left: -20px !important;
  }
}

/* Tailwind CSS animations (Add to your global CSS if needed) */
.animate-fade-in {
  animation: fade-in 0.5s ease-out;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
