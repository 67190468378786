.page_title {
  padding: 200px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.page_title::after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000000c5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.page_title h1 {
  font-size: 40px;
  font-weight: 700;
  color: #008eff;
}

.page_title ul li {
  color: rgba(255, 255, 255, 0.603);
}

.page_title ul li:last-child {
  color: #008cff7a;
}

@media only screen and (max-width: 900px) {
  .page_title {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 600px) {
  .page_title {
    padding: 60px 0;
  }
  .page_title h1 {
    font-size: 30px;
  }
}
