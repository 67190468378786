.about_page {
  background-color: #fff;
}
.short_disc h1 {
  font-size: 40px;
  font-weight: 600;
  color: #008eff;
  margin-bottom: 20px;
}

.short_disc p {
  margin-bottom: 15px;
}

.short_disc img {
  width: 500px;
  margin: auto;
}

@media only screen and (max-width: 1024px) {
  .short_disc img {
    width: 400px;
  }
  .short_disc h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 635px) {
  .short_disc h1 {
    font-size: 25px;
    margin-bottom: 5px;
  }
  .short_disc p {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 540px) {
  .short_disc {
    display: block !important;
    text-align: center;
  }

  .short_disc img {
    margin-bottom: 15px;
    width: 300px;
  }
}
