.contact_form {
  padding: 100px 0;
  background-color: #000000;
  background-image: url("https://i.ibb.co/f88wNph/download.png");
  background-position: bottom;
  background-repeat: repeat-x;
}

.contact_info a {
  width: fit-content;
  color: #2e2e2ec7;
  transition: 0.5s;
}

.contact_info a div {
  transition: 0.5s;
}

.contact_info a:hover {
  color: #008eff;
}

.contact_info a:hover div {
  background-color: #008eff;
  color: white !important;
}

@media only screen and (max-width: 768px) {
}
