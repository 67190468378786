.box {
  height: 250px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.box::after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000000b4;
  top: 0;
  left: 0;
  position: absolute;
  transition: 0.5s;
  opacity: 0;
}

.box:hover::after {
  opacity: 1;
}

.box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.box:hover img {
  transform: scale(1.1);
}

.info {
  position: absolute;
  z-index: 99;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}

.box:hover .info {
  opacity: 1;
}

.info h1 {
  font-size: 25px;
  color: white;
}
