.newslatter {
  position: relative;
  z-index: 1;
  padding: 200px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.newslatter::after {
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(95deg, #000000ea, #000000b4, #008cff8c);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.newslatter input {
  border: 1px solid #008eff;
  border-radius: 8px 0 0 8px !important;
  width: 30%;
}

.newslatter h2 {
  color: #008eff;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.newslatter p {
  width: 50%;
  color: rgba(255, 255, 255, 0.705);
  margin-top: 20px;
}

@media only screen and (max-width: 1023px) {
  .newslatter p {
    width: 80%;
  }
  .newslatter input {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .newslatter {
    padding: 100px 0;
  }
  .newslatter p {
    width: 90%;
    font-size: 14px;
  }
  .newslatter input {
    width: 80%;
  }
}
