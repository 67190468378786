.button_hover {
  transition: 0.5;
  border: 1px solid transparent !important;
  border-radius: 5px !important;
}

.button_hover:hover {
  background-color: #fff !important;
  border: 1px solid #008eff !important;
  color: #008eff !important;
}
