/* Portfolio SIingle */
@media only screen and (max-width: 1023px) {
  .portfolio .card-body h2.card-title {
    font-size: 15px !important;
  }
}

.desc {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
