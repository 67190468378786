.header {
  background-color: #000000;
  padding: 10px 0;
  position: sticky;
  top: -1px;
  left: 0;
  width: 100%;
  z-index: 999;
  /* border-bottom: 1px solid #011627; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.349);
}

.header_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  display: flex;
  align-items: center;
}

.logo a h1 {
  margin-left: 5px;
  margin-bottom: -18px;
}

.logo img {
  width: 35px;
}

.menu ul {
  display: flex;
  align-items: center;
  gap: 25px;
}

.menu ul li a,
.menu ul li button {
  color: white;
  font-size: 13px;
  font-weight: 500;
}

.serviceHover {
  transition: 0.5s;
}

.menu ul li:hover .serviceHover {
  top: 66px;
  visibility: visible;
  opacity: 1;
}

/* .menu ul li {
  position: relative;
} */

/* .menu ul li:hover ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul li ul {
  position: absolute;
  width: 350px;
  background-color: #fff;
  top: 100%;
  left: 0;
  border-radius: 0 5px 5px 5px;
  display: block;
  transform: translateY(20px);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #ddd;
}
.menu ul li ul li {
  width: 100%;
}

.menu ul li ul li a,
.menu ul li ul li button {
  color: black;
  border-bottom: 1px solid #00000013;
  width: 100%;
  display: flex;
  font-size: 17px;
  padding: 10px 15px;
  font-weight: 500;
  transition: 0.5s;
  text-align: left;
}

.menu ul li ul li a:hover,
.menu ul li ul li button:hover {
  background-color: #008eff;
  color: white;
}

.menu ul li ul li ul {
  top: 0;
  right: 100% !important;
  left: inherit;
  opacity: 0 !important;
  visibility: hidden !important;
  transition: 0.5s;
  transform: translateY(20px) !important;
}

.menu ul li ul li:hover ul {
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(0) !important;
} */
