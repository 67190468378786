.testimonial_section {
  padding: 100px 0;
  background-color: #000000;
  background-image: url("https://i.ibb.co/f88wNph/download.png");
  background-position: bottom;
  background-repeat: repeat-x;
}

.testimonial_box {
  border: 1px solid #ffffff25;
  border-radius: 5px;
  padding: 10px;
  background-color: #000000;
  .testimonial_content {
    background-image: linear-gradient(221deg, #7c7c7ca6, #008efa73);
    padding: 20px;
    position: relative;
    border-radius: 7px 7px 7px 0;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 30px 0 0px;
      border-color: #00426c transparent transparent transparent;
      position: absolute;
      bottom: -15%;
      left: 0;
      // z-index: -1;
    }
  }

  .testimonial_auth {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .testimonial_auth_left {
      width: 70%;
      display: flex;
      align-items: center;

      img {
        height: 60px;
        width: 60px;
        object-fit: cover;
        border-radius: 50%;
      }

      .testimonial_auth_name {
        margin-left: 5px;

        h3 {
          font-size: 15px;
          color: #ffffff;
        }
        p {
          font-size: 14px;
          color: #b8b8b8;
        }

      }
    }
    .testimonial_auth_right {
      width: 30%;
      text-align: right;
      ul {
        display: flex;
        justify-content: flex-end;
        li {
          color: #0c9ccb;
        }
      }
      span {
        font-size: 12px;
      }
    }
  }
}
