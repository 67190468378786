.title {
  text-align: center;
  width: 80%;
}

.title h1 {
  font-size: 40px;
  color: black !important;
  margin-bottom: 5px;
  line-height: 50px;
}

.title p {
  width: 90%;
  margin: auto;
}

.span {
  width: 200px;
  height: 5px;
  background-color: #158cfcaa;
  display: inline-block;
  border-radius: 5px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .title {
    width: 80% !important;
  }
}

@media only screen and (max-width: 900px) {
  .title h1 {
    font-size: 25px;
    margin-bottom: 5px;
  }

  .title p {
    font-size: 14px;
  }
}
