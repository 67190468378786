.choose_box {
  background-color: #fff;
  border-radius: 0px 0px 30px 0px;
  padding: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
  margin-top: 50px;
  transition: 0.5s;
}

.icon {
  background-color: #008eff;
  display: inline-block;
  padding: 20px;
  color: white;
  margin-top: -65px;
  margin-bottom: 15px;
  transition: 0.5s;
}

.choose_box h1 {
  font-size: 22px;
  color: #008eff;
  font-weight: 600;
  margin-bottom: 5px;
  transition: 0.5s;
}

.choose_box p {
  font-size: 16px;
}

.choose_box:hover {
  border-radius: 0;
  background-color: #008eff;
  color: rgb(235, 235, 235);
}

.choose_box:hover .icon {
  background-color: #fff;
  color: #008eff;
}

.choose_box:hover h1 {
  color: #fff;
}
